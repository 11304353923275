
import { mixins, Options } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import OverlayPanel from "primevue/overlaypanel";
import { AccountController } from "@/app/ui/controllers/AccountController";
import InputDimension from "@/app/ui/components/input-dimension/index.vue";
import InputAdornment from "@/app/ui/components/input-adornment/index.vue";
import { StiDestSttDetail } from "@/domain/entities/StiDest";
import {
  convertDecimal,
  convertDecimalAfterComma,
  convertDecimalWithComma,
  ellipsisString,
  storeDatatoLocalStorage,
  removeDataLocalStorage
} from "@/app/infrastructures/misc/Utils";
import { StiDestController } from "@/app/ui/controllers/StiDestController";
import OutGoingMixins from "@/app/ui/views/out-going-shipment/out-going-mixin";
import { playNotification } from "@/app/infrastructures/misc/UtilsAudio";
import debounce from "lodash/debounce";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { TrackingController } from "@/app/ui/controllers/TrackingController";
import { TrackingSttData, TrackingSttDetailData } from "@/domain/entities/TrackingStt";
import isFormatLiloBag from "@/app/infrastructures/misc/common-library/isFormatLiloBag";

@Options({
  components: {
    DetailLayout,
    OverlayPanel,
    InputDimension,
    InputAdornment
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.openSuccess && !this.isPartial) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      next();
      this.setPartial(false);
      this.setOpenSuccess(false);
    }
  }
})
export default class CreateFormStiDestManifest extends mixins(OutGoingMixins) {
  pagination = {
    page: 1,
    limit: 50
  };
  mounted() {
    AccountController.checkAccountLocation();
    this.setOpenSuccess(false);
  }

  // delete stt number
  sttNumberListTrash: Array<string> = [];
  showDeleteSttNumber = false;
  sttNumberToDelete = "";
  sttNumberSuccessDelete = "";
  errorSttNumberDelete = "";
  async onValidateDeleteSttNumber(val: any) {
    const splitVal = val.split("#");
    if (splitVal.length > 0) this.sttNumberToDelete = splitVal[0];
    this.onDeleteSttNumber();
  }
  onDeleteSttNumber() {
    if (this.sttNumberToDelete) {
      this.errorSttNumberDelete = "";
      this.sttNumberSuccessDelete = "";
      this.sttNumberToDelete = this.sttNumberToDelete.toUpperCase();

      // find index no. stt
      const indexSttNumber = this.dataSttToBeUpdate.findIndex((data: any) => {
        return (
          data.sttNo === this.sttNumberToDelete ||
          data.sttElexysNumber === this.sttNumberToDelete
        );
      });

      // no. stt not found
      if (indexSttNumber < 0) {
        this.errorSttNumberDelete = "No. STT tidak ditemukan/sudah terhapus";
      } else {
        this.dataSttToBeUpdate.splice(indexSttNumber, 1);
        this.sttNumberListTrash.push(this.sttNumberToDelete);
        this.sttNumberSuccessDelete = this.sttNumberToDelete;
        this.sttNumberToDelete = "";
      }
    }
    const deleteStt: any = this.$refs.deleteStt;
    deleteStt?.focus();
  }
  onShowDelete() {
    this.errorSttNumberDelete = "";
    this.showDeleteSttNumber = true;
    this.sttNumberToDelete = "";
    this.sttNumberSuccessDelete = "";
  }
  onCloseDelete() {
    this.showDeleteSttNumber = false;
    this.sttNumberListTrash = [];
  }

  // get profile account
  get dataProfile() {
    return AccountController.accountData;
  }

  // validate stt number
  isMisRoute = false;
  isTransit = false;
  isStiDest = false;
  get sttNumberTypeStatus() {
    if (this.isMisRoute)
      return {
        icon: "warning",
        title: "MIS-ROUTE",
        color: "bg-red-lp-100"
      };
    else if (this.isTransit)
      return {
        icon: "warning-yellow",
        title: "Transit",
        color: "bg-yellow-lp-300"
      };
    else if (this.isStiDest)
      return {
        icon: "image-modal-success",
        title: "STI-DEST",
        color: "bg-green-lp-100"
      };
    return false;
  }

  // handle stt number
  isPaid = true;
  isPickupTrucking = false;
  isUnpaidNotification = false;
  errorSttNumber = "";
  isValidateScan = false;

  onChangeStt() {
    this.isPaid = true;
    this.isPickupTrucking = false;
    this.onCloseNotification();
  }
  get errorCaption() {
    if (!this.isPaid && this.isPickupTrucking) {
      return "Driver truck belum mengupdate status drop off & paket mengalami kelebihan berat sehingga tidak dapat di update ke STI Dest";
    } else if (!this.isPaid) {
      return "Terjadi kelebihan berat sehingga status tidak dapat diupdate ke STI Dest.";
    } else if (this.isPickupTrucking) {
      return "Driver truck belum mengupdate status ke drop off sehingga tidak dapat di update ke STI Dest";
    }
    return "";
  }
  get messageNotification(): string {
    if (!this.isPaid && this.isPickupTrucking) {
      return "Mohon menunggu driver untuk update status drop off trucking & customer melunasi kurang bayar";
    } else if (!this.isPaid) {
      return "Tunggu sampai customers selesai melunasi tagihan kurang bayar";
    } else if (this.isPickupTrucking) {
      return "Tunggu sampai driver selesai mengupdate status ke drop off trucking";
    }
    return "";
  }

  onCloseNotification() {
    this.isUnpaidNotification = false;
    this.notification = false;
  }

  savedSttNo = "";

  onResetForm() {
    this.isMisRoute = false;
    this.isTransit = false;
    this.isStiDest = false;
    this.form.sttNumber = "";
    this.isErrorValidationScan = false;
    this.errorSttNumber = "";
    this.isErrorTyping = false;
    // re focus
    const inputStt: any = this.$refs?.inputStt;
    inputStt?.focus();
  }

  //via scan
  isScan = false;
  addScanSttNumber = debounce(() => {
    if (!this.isDisableAddSttNumber) {
      this.isErrorValidationScan = false;
      this.isScan = true;
      this.addSttNumberToUpdate();
    }
  }, 500);
  addSttNumber() {
    if (!this.isDisableAddSttNumber) {
      this.isScan = false;
      this.addSttNumberToUpdate();
    }
  }

  checkIsSTTOrBag(val: string) {
    return !!val.trim().match(/^\d{1,2}LP\w+$/g) || !!val.trim().match(/^\w{1,3}-\w+$/g) || this.isLiloBag(val);
  }

  async handleScanOthers(str: string) {
    MainAppController.showLoading();
    try {
      const resp = await TrackingController.trackStt({
        sttNo: str,
        isGetSla: false,
        isSecondCall: false,
        cache: true,
      });
      if (resp) {
        return {
          sttNo: TrackingController.trackingSttData.sttDetail.sttNo,
          noRefExternal: str,
        };
      } else {
        playNotification("error");
        this.errorSttNumber = "No. STT/Bagging tidak ditemukan";
        this.isErrorValidationScan = true;
        this.isValidateScan = false;
      }
    } finally {
      TrackingController.setTrackingSttData(
        new TrackingSttData(new TrackingSttDetailData(), [])
      );
      MainAppController.closeLoading();
    }
  }

   async handleValidationSTTOthers():Promise<{ sttNo: string; noRefExternal: string; }> {
    let sttNoOthers: any = { sttNo: "", noRefExternal: "" };
    const isSTT = this.checkIsSTTOrBag(this.form.sttNumber);
    if (!isSTT) {
      sttNoOthers = await this.handleScanOthers(this.form.sttNumber);
    }
    return sttNoOthers;
  }

  async addSttNumberToUpdate() {
    this.onCloseNotification();
    const sttNoOthers = await this.handleValidationSTTOthers();
    if (this.isLiloBag(this.form.sttNumber)) {
      const res = await StiDestController.getStiDestSttListLilo({
        bagNo: this.form.sttNumber
      });
      this.addStt(res, "");
    } else {
      const res = await StiDestController.getStiDestSttList({
        bagOrStt: !this.checkIsSTTOrBag(this.form.sttNumber)
          ? sttNoOthers?.sttNo ?? ""
          : this.form.sttNumber
      });
      this.addStt(res, sttNoOthers?.noRefExternal || "");
    }
  }

  isLiloBag(bagNo: string) {
    return isFormatLiloBag(bagNo)
  }

  addStt(fetchDetailSTT: any, noRefExternal: string) {
    const checkErrorUpdate = fetchDetailSTT.find(
      (item: any) => item.isAllowUpdateStatus === false
    );
    const isPickupTrucking = fetchDetailSTT.find(
      (item: any) => item.lastStatus === "PICKUP_TRUCKING"
    );
    if (checkErrorUpdate && !isPickupTrucking) {
      playNotification("error");
      this.isErrorValidationScan = true;
      this.errorSttNumber = checkErrorUpdate.errorMessage;
    } else {
      for (const detailSTT of fetchDetailSTT) {
        this.onValidateSttResult(fetchDetailSTT, detailSTT, checkErrorUpdate, noRefExternal);
      }
      if (this.isValidateScan) {
        // reset form
        this.savedSttNo = this.form.sttNumber;
        this.form.sttNumber = "";
        if (!this.isErrorValidationScan) {
          this.isValidateScan = false;
        }
        this.notification = true;
        // re focus
        const inputStt: any = this.$refs?.inputStt;
        inputStt?.focus();
        // remove notification after 15 second
        if (this.isPaid) {
          setTimeout(() => {
            this.notification = false;
            this.isStiDest = false;
            this.isMisRoute = false;
            this.isTransit = false;
          }, 15000);
        }
      }
    }
  }

  onValidateSttResult(
    fetchDetailSTT: any,
    detailSTT: any,
    checkErrorUpdate: boolean,
    noRefExternal: string
  ) {
    const checkAllPayment = fetchDetailSTT.filter(
      (item: any) => item.isPaid === false
    );
    const indexCurrentSttNumber = this.dataSttToBeUpdate.findIndex(
      (data: any) => {
        return data.sttNo === detailSTT.stt.sttNo;
      }
    );
    if (indexCurrentSttNumber > -1) {
      playNotification("error");
      this.errorSttNumber = "No. STT sudah di input";
      this.isErrorValidationScan = true;
      this.isValidateScan = false;
    } else {
      this.isPaid = detailSTT.isPaid;
      this.isPickupTrucking =
        !!detailSTT?.lastStatus?.match(/^PICKUP_TRUCKING$/) && checkErrorUpdate;
      this.isValidateScan = true;

      if (this.isPaid && !this.isPickupTrucking && !checkAllPayment.length) {
        // show status stt success
        this.isTransit = detailSTT.stt.isTransit;
        this.isMisRoute = detailSTT.stt.isMissroute;
        this.isStiDest = !detailSTT.stt.isTransit && !detailSTT.stt.isMissroute;
        detailSTT.stt.sttReferenceNo = noRefExternal;
        this.dataSttToBeUpdate.push(detailSTT.stt);
        playNotification("success");
      } else {
        playNotification("error");
        this.isUnpaidNotification = true;
      }
      this.detailSttNotification = {
        sttNumber: detailSTT.stt.sttNo || this.form.sttNumber,
        destinationCity: `${detailSTT.stt.sttDestinationCityId} - ${detailSTT.stt.sttDestinationCityName}`,
        product: detailSTT.stt.sttProductType,
        woodPacking: detailSTT.stt.sttWoodPacking ? "Ya" : "Tidak",
        commodity: detailSTT.stt.sttCommodityName,
        totalPieces: detailSTT.stt.sttTotalPiece,
        codAmount: detailSTT.stt.sttCodAmount
      };
    }
  }

  get totalStt() {
    return this.dataSttToBeUpdate.length;
  }
  get calculatedStt() {
    return this.dataSttToBeUpdate.reduce(
      (a: any, b: any) => {
        return {
          totalPieces: a.totalPieces + b.piece.length,
          totalGrossWeight:
            a.totalGrossWeight +
            b.piece.reduce((c: any, d: any) => {
              return c + d.sttPieceGrossWeight;
            }, 0),
          totalVolumeWeight:
            a.totalVolumeWeight +
            b.piece.reduce((c: any, d: any) => {
              return c + d.sttPieceVolumeWeight;
            }, 0)
        };
      },
      {
        totalPieces: 0,
        totalGrossWeight: 0,
        totalVolumeWeight: 0
      }
    );
  }
  get totalPieces() {
    return this.calculatedStt.totalPieces;
  }
  get totalGrossWeight() {
    return this.calculatedStt.totalGrossWeight;
  }
  get totalVolumeWeight() {
    return this.calculatedStt.totalVolumeWeight;
  }

  // STI-DEST-SC
  get totalBagVolumeWeight() {
    const total =
      (this.form.length * this.form.width * this.form.height) / 6000;
    return total % 1 === 0 ? total : total.toFixed(1);
  }

  // alert popup
  isErrorTyping = false;
  isErrorValidationScan = false;
  isPartial = false;
  get sttFailedUpdate() {
    return StiDestController.sttFailed;
  }
  setPartial(value: boolean) {
    this.isPartial = value;
  }
  get isModal(): boolean {
    return (
      this.openSuccess ||
      this.isErrorTyping ||
      this.isFailed ||
      this.isPartial ||
      this.isErrorValidationScan
    );
  }
  get alertPopup() {
    // if success
    if (this.openSuccess)
      return {
        onClick: () => this.onCloseSuccess(),
        title: "Perubahan Status Berhasil !",
        message: "Status STT berhasil diupdate menjadi STI-DEST.",
        image: "image-modal-success"
      };
    // if input by scan
    if (this.isErrorValidationScan)
      return {
        onClick: () => this.onResetForm(),
        title: `${this.isScan ? "Scan" : "Tambah"} No. STT Gagal`,
        message: this.errorSttNumber,
        image: "image-modal-failed"
      };
    // if success partially
    if (this.isPartial)
      return {
        onClick: () => this.onCloseSuccess(),
        onDownload: () =>
          this.downloadCsvFailedStt({
            fileName: "sti_dest_stt_failed.csv",
            listStt: this.sttFailedUpdate
          }),
        title: "Perubahan Status Berhasil !",
        message:
          "Beberapa status STT berhasil diupdate menjadi STI-DEST, namun terdapat kegagalan pada beberapa STT. Silahkan download untuk mengetahui kesalahan.",
        image: "image-modal-warning"
      };
    // default is failed
    return {
      onClick: () => this.setFailed(false),
      onDownload: () =>
        this.downloadCsvFailedStt({
          fileName: "sti_dest_sc_stt_failed.csv",
          listStt: this.sttFailedUpdate
        }),
      title: "Perubahan Status Gagal !",
      message: "Perubahan status STI-DEST gagal dibuat.",
      image: "image-modal-failed"
    };
  }

  // form model
  form = {
    date: new Date(),
    sttNumber: "",
    removeSttNumber: "",
    weight: 1,
    length: 30,
    width: 20,
    height: 10
  };

  get isDisableAddSttNumber() {
    return !this.form.sttNumber || this.totalStt >= 500;
  }

  // Origin Transit City
  get arrivalCity() {
    return `${this.dataProfile.account_location.city_code} - ${this.dataProfile.account_location.city_name}`;
  }

  // convert value decimal
  isValueDecimal(value: number): number | string {
    return convertDecimal(value);
  }

  // format total kg
  convertTotalKg(value: number) {
    return convertDecimalAfterComma(value, 1);
  }

  // panel pickup
  togglePanel(event: any) {
    const refs: any = this.$refs;
    refs.op?.toggle(event);
  }

  // notification handler
  notification = false;
  detailSttNotification: any = null;

  // table
  sourceSttFromLocalStorage: any = JSON.parse(
    localStorage.getItem("sti-dest") || "[]"
  );
  get dataSttToBeUpdate() {
    storeDatatoLocalStorage("sti-dest", this.sourceSttFromLocalStorage);
    return this.sourceSttFromLocalStorage;
  }
  get columns() {
    return [
      {
        name: "No.",
        styleHead: "w-10 text-center whitespace-no-wrap",
        styleCustom: "align-top",
        render: (_: any, index: number) => {
          return `<div class="text-black-lp-300">${index +
            1 +
            this.pagination.limit * (this.pagination.page - 1)}</div>`;
        }
      },
      {
        name: "No. Bag",
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: StiDestSttDetail) => {
          return `<div class="text-black-lp-300">${item.bagVendorNo ||
            item.bagNo || "-"}</div>`;
        }
      },
      {
        name: `No. STT`,
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: StiDestSttDetail) => {
          return `<div class="text-black-lp-300">${item.sttNo}</div>`;
        }
      },
      {
        name: `No. Referensi`,
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: StiDestSttDetail) => {
          return `<div class="text-black-lp-300">${item.sttReferenceNo || "-"}</div>`;
        }
      },
      {
        name: "Produk",
        styleHead: "w-44 text-left whitespace-no-wrap",
        render: (item: StiDestSttDetail) => {
          return `<div class="text-black-lp-300 flex">
                    <div class="rounded px-2 py-0 bg-gray-lp-400">
                        ${item.sttProductType}
                    </div>
                  </div>`;
        }
      },
      {
        name: "Komoditas",
        styleHead: "w-64 text-left whitespace-no-wrap",
        render: (item: StiDestSttDetail) => {
          return `<div class="text-black-lp-300">${ellipsisString(
            item.sttCommodityName,
            25
          )}</div>`;
        }
      },
      {
        name: "Total Koli",
        styleHead: "w-24 text-left whitespace-no-wrap",
        render: (item: StiDestSttDetail) => {
          return `<div class="text-black-lp-300">${item.sttTotalPiece}</div>`;
        }
      },
      {
        name: "Berat Kotor",
        styleHead: "w-36 text-left whitespace-no-wrap",
        render: (item: StiDestSttDetail) => {
          return `<div class="text-black-lp-300">${convertDecimalWithComma(
            item.sttGrossWeight,
            2
          )} Kg</div>`;
        }
      },
      {
        name: "Berat Dimensi",
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: StiDestSttDetail) => {
          return `<div class="text-black-lp-300">${convertDecimalWithComma(
            item.sttVolumeWeight,
            2
          )} Kg</div>`;
        }
      },
      {
        name: "Dest.",
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: StiDestSttDetail) => {
          return `<div class="text-black-lp-300 flex">
                    <div class="rounded px-2 py-0 bg-gray-lp-400">
                        ${item.sttDestinationCityId}
                    </div>
                  </div>`;
        }
      },
      {
        name: "Transit",
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: StiDestSttDetail) => {
          return `<div class="text-black-lp-300">${
            item.isTransit ? "Ya" : "Tidak"
          }</div>`;
        }
      },
      {
        name: "Mis-Route",
        styleHead: "w-56 text-left whitespace-no-wrap",
        render: (item: StiDestSttDetail) => {
          return `<div class="text-black-lp-300">${
            item.isMissroute ? "Ya" : "Tidak"
          }</div>`;
        }
      }
    ];
  }
  // role account user
  get roleAccount() {
    return (
      (this.dataProfile.account_type === "partner"
        ? this.dataProfile.account_type_detail.type
        : this.dataProfile.account_type) || "internal"
    );
  }

  withPrint = false;
  countSuccess = 0;
  countFailed = 0;
  async onSave() {
    this.handleConfirmationSave(false);
    const resp = await StiDestController.onGenerateStiDest({
      sttNumbers: this.dataSttToBeUpdate
    });

    if (resp.data) {
      if (resp.data.totalSttFailed && !resp.data.totalSttSuccess) {
        this.countFailed = resp.data.totalSttFailed;
        this.setFailed(true);
        StiDestController.setSttFailed(resp.data.sttFailed);
      } else if (resp.data.totalSttFailed && resp.data.totalSttSuccess) {
        this.countFailed = resp.data.totalSttFailed;
        this.countSuccess = resp.data.totalSttSuccess;
        this.isPartial = true;
        StiDestController.setSttFailed(resp.data.sttFailed);
      } else {
        StiDestController.setOpenSuccess(true);
        if (this.withPrint) {
          this.getDetailAndPrint(StiDestController.StiDestIdToGeneratePdf);
        }
      }
      this.sourceSttFromLocalStorage = [];
    }
    this.handleConfirmationSave(false);
  }

  createAndPrint(withPrint: boolean) {
    const refs: any = this.$refs;
    refs.op?.hide();
    this.withPrint = withPrint;
    this.handleConfirmationSave(true);
  }

  async getDetailAndPrint(id: number) {
    const printManifest = await StiDestController.getStiDestManifestDetail({
      id
    });

    if (printManifest) {
      const detailManifest = StiDestController.StiDestManifestData;
      const print: any = await import(
        "@/app/ui/views/incoming-process/sti-dest/modules/print.vue"
      );
      const logo = await import(
        `@/app/ui/assets/images/logo-${this.roleAccount}.png`
      );
      print.default.methods.printManifest(detailManifest, logo.default);
    }
  }

  // handle route leave and modal
  // navigation
  get openSuccess(): boolean {
    return StiDestController.isOpenSuccess;
  }
  setOpenSuccess(value: boolean) {
    StiDestController.setOpenSuccess(value);
  }

  // failed for create
  get isFailed(): boolean {
    return StiDestController.isFailed;
  }
  setFailed(value: boolean) {
    StiDestController.setFailed(value);
  }

  openConfirmationLeave = false;
  openConfirmationSave = false;
  answer = false;
  newPath = "";
  handleConfirmationLeave(value: boolean) {
    this.openConfirmationLeave = value;
  }
  handleConfirmationSave(value: boolean) {
    this.openConfirmationSave = value;
  }
  goBack() {
    this.$router.push("/sti-dest");
  }
  onOpenClosePage(to: any) {
    this.handleConfirmationLeave(true);
    this.newPath = to.path;
  }
  handlerClose() {
    removeDataLocalStorage("sti-dest");
    this.handleConfirmationLeave(false);
    this.answer = true;
    this.$router.push(this.newPath);
  }
  handlerCancelClose() {
    this.answer = false;
    this.handleConfirmationLeave(false);
  }
  onCloseSuccess() {
    this.goBack();
  }
}
